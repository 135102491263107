<template>
    <div class="rating-text-content">
        <div>
            За активность на заданиях и участие в интерактивах, указанных в
            <span @click="$router.push({ name: 'Activities List' })">Таблице активностей</span>, все участники получают
            байты. От количества байтов зависит ваше место в рейтинге на «<span
                @click="$router.push({ name: 'Ratings' })"
                >Доске почёта</span
            >». Участники, набравшие больше всех байтов получают призы.
        </div>
        <div class="help-text-block__bold">
            Обратите внимание, рейтингов несколько: общий и отдельный по каждой миссии.
        </div>
    </div>
</template>

<script>
export default {
    name: 'rating-text-content',
};
</script>

<style lang="scss" scoped>
.rating-text-content {
    & > div:first-child {
        span {
            text-decoration: underline;
            cursor: pointer;
            transition: color 50ms ease-out;
            &:hover {
                color: #ffffffbf;
            }
        }
    }
    & > .help-text-block__bold {
        margin-top: 1rem;
    }
}
</style>
