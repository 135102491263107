<template>
    <div class="translation-text-content">
        <div class="help-text-block__bold">Программа обучения подразумевает 2 формата участия:</div>
        <div class="help-text-block__bold">
          Прямая трансляция будет проходит с использованием плеера трансляции YouTube. Во время трансляции вам доступен чат. Чтобы им пользоваться вам нужно быть авторизованным под своим аккаунтом в YouTube.
        </div>
        <div class="help-text-block__bold">
          На месте чата будут появляться интерактивы (голосования, реакции, мнения).
        </div>
    </div>
</template>

<script>
export default {
    name: 'translation-text-content',
};
</script>

<style lang="scss" scoped>
.translation-text-content {
    & > div:nth-child(2) {
        margin: 1rem 0;
    }
  & >.help-text-block__bold:last-child{
    margin-top: 1rem;
  }
}
</style>
