<template>
    <div class="platform-text-content">
        <div class="help-text-block__subtitle">
            Спасибо, что прошли идентификацию! Вам открыт доступ ко всем возможностям платформы.
        </div>
        <div>
            Вы можете:
            <ul class="help-text-block__list">
                <li>Узнавать больше о мероприятиях. Мы называем их заданиями</li>
                <li>Регистрироваться на задания: они проходят очно и онлайн</li>
                <li>Смотреть прямые трансляции</li>
                <li>Задавать вопросы в чате или голосом</li>
                <li>Участвовать в интерактивах</li>
                <li>Зарабатывать байты — это баллы рейтинга, которые можно обменять на подарки</li>
            </ul>
        </div>
        <div class="help-text-block__subtitle">Платформа — это ваша цифровая штаб-квартира, а вы — цифровой агент!</div>
    </div>
</template>

<script>
export default {
    name: 'platform-text-content',
};
</script>

<style lang="scss" scoped>
.platform-text-content {
    & > .help-text-block__subtitle:not(:last-child) {
        margin-bottom: 1rem;
    }
}
</style>
