<template>
    <div class="interaction-text-content">
        <div class="help-text-block__bold">Во время заданий вы сможете поучаствовать в интерактивах.</div>
        <div>
            Если вы участвуете очно, спикер покажет QR-код со ссылкой на интерактивы. Наведите на него камеру телефона,
            перейдите по ссылке и авторизуйтесь.
        </div>
        <div>Если вы участвуете онлайн, интерактивы появятся справа от трансляции.</div>
    </div>
</template>

<script>
export default {
    name: 'interaction-text-content',
};
</script>

<style lang="scss" scoped>
.interaction-text-content {
    & > div:nth-child(2) {
        margin: 1rem 0;
    }
}
</style>
