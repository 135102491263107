<template>
    <b-container class="help-view">
        <h2>Инструкции</h2>
        <div class="help-view__content">
            <div class="help-view__mascot">
                <img src="@/assets/images/mascote-agent-a.png" alt="" />
                <div>Агент А</div>
                <div>директор Секретной Службы</div>
            </div>
            <div class="help-view__main">
                <div class="help-view__tabs">
                    <div
                        v-for="(tab, index) in config"
                        :key="tab.tabName"
                        :class="{ active: activeIndex === index }"
                        class="tab"
                        @click="activeIndex = index"
                    >
                        {{ tab.tabName }}
                    </div>
                </div>
                <div class="help-view__content">
                    <div class="content">
                        <div class="content__top">
                            <transition name="fade" mode="out-in">
                                <div class="content__left" :key="activeIndex">
                                    <div class="content__title">{{ currentTab.title }}</div>

                                    <component :is="currentTab.component"></component>
                                </div>
                            </transition>
                            <transition name="fade" mode="out-in">
                                <div class="content__right" :key="activeIndex">
                                    <img :src="currentTab.image" :alt="currentTab.title" />
                                </div>
                            </transition>
                        </div>
                        <div class="content__action">
                            <al-btn @click="previous">Назад</al-btn>
                            <al-btn @click="next">Далее</al-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
import AlBtn from '@/components/AlBtn';
import PlatformTextContent from '@/components/instruction-text-content/platform-text-content.vue';
import EducationTextContent from '@/components/instruction-text-content/education-text-content.vue';
import TranslationTextContent from '@/components/instruction-text-content/translation-text-content.vue';
import RatingTextContent from '@/components/instruction-text-content/rating-text-content.vue';
import InteractionTextContent from '@/components/instruction-text-content/interaction-text-content.vue';

const config = [
    {
        tabName: 'О платформе',
        title: 'Возможности платформы',
        image: require('@/assets/images/help-page/platform.png'),
        component: PlatformTextContent,
    },
    {
        tabName: 'Обучение',
        title: 'Формат обучения',
        image: require('@/assets/images/help-page/education.png'),
        component: EducationTextContent,
    },
    {
        tabName: 'Трансляции',
        title: 'Трансляции',
        image: require('@/assets/images/help-page/translation.png'),
        component: TranslationTextContent,
    },
    {
        tabName: 'Рейтинг',
        title: 'Рейтинг',
        image: require('@/assets/images/help-page/rating.png'),
        component: RatingTextContent,
    },
    {
        tabName: 'Интерактивы',
        title: 'Интерактивы',
        image: require('@/assets/images/help-page/interaction.png'),
        component: InteractionTextContent,
    },
];

export default {
    name: 'Instuction',
    components: {
        AlBtn,
    },
    data() {
        return {
            activeIndex: 0,
            config,
        };
    },
    computed: {
        currentTab() {
            return this.config[this.activeIndex];
        },
    },
    methods: {
        next() {
            if (this.activeIndex < this.config.length - 1) {
                return this.activeIndex++;
            }
            this.activeIndex = 0;
        },
        previous() {
            if (this.activeIndex !== 0) {
                return this.activeIndex--;
            }
            this.activeIndex = this.config.length - 1;
        },
    },
};
</script>
