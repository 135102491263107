<template>
    <div class="education-text-content">
        <div class="help-text-block__bold">Вы можете прийти на задания очно или онлайн.</div>
        <div>
            <ul class="help-text-block__list">
                <li>Очно по адресу: город Москва, Проспект Андропова, д.18, корп. 3 (1 этаж)</li>
                <li>Онлайн на платформе с прямой трансляцией. Ссылка появится в вашем календаре после регистрации</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'education-text-content',
};
</script>

<style scoped>
.education-text-content > div > .help-text-block__list {
    margin: 1rem 0;
}
</style>
